:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: 'Merriweather';
    --alternative-font-family: 'Merriweather';
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #48648B;
    --link-hover-color: #000;
    --alternative-color: #D98D57;
    --f-color: #fff;
    --map-marker-color: 000000;
}

@import "/css/normalize.css";
@import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Open Sans/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Roboto/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
@import "/fonts/Merriweather/all.css"; /* 300, 300i, 400, 400i, 700, 700i, 900, 900i */
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}

.f-color {
    color:var(--f-color);
}

.inner {
    padding: 1em;
}


/* Header
---------------------------------------------------------*/
.topsocial a svg.fa-icon {
    fill:#b8d2e9 !important;
}

.topsocial a:hover svg.fa-icon {
    fill:white !important;
}

.topsocial a {
    padding:0 0.5em;
}

.topmail a {
    padding:0 1em;
}

.topmail a:last-child {
    padding-right:0;
}

.topcontact {
    padding:0.5em 0;
}

.topcontact span {
    display:block;
    font-style:italic;
    color:white;
    font-size:1.3rem;
    padding-right:1em;
}

.topcontact a {
    color:white;
}

.topcontact a svg.fa-icon {
    fill:white;
}

.topmenu .container {
    padding:0;
}

.slogan {
    color:var(--link-color);
    font-weight:700;
    font-size:1.3rem;
    max-width:400px;
    width:100%;
}

.topmenu .container {
    border-top:var(--link-color) solid 1px;
}

.toplogo {
    padding:0.5em 0;
}


header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
    margin-right:1px;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:white;
    z-index:100;
    box-shadow: 0 4px 2px -2px gray;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#000;
    border-right:none;
    border-bottom:#eee solid 1px;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:#b8d2e9;
}

.menu .sub-menu-items li a {
    margin:0;
}

ul.menu > .sub-menu > a:after {
content: "▾";
    position: absolute;
    right: 0px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}

ul.menu > .sub-menu > ul > .sub-menu > a:after {
content: "▸";
    position: absolute;
    right: 5px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.start-slider .container {
    padding:0;
}

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.homepage .slick,
.homepage .slick-slide,
.homepage .slick-substitute > div {
    height:100%;
    min-height: 380px;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slick-title {
    text-align:center;
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title h2 {
    display:block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0.5em;

}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/


/* startboxar */
.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.pop-img {
    position:relative;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center center;
    min-height:276px;
    display:flex;
    align-items:center;
}


.startbox-wrapper .pop-img svg.fa-icon {
    height:auto;
    width: 4em;
    fill: var(--alternative-color);
    vertical-align:middle !important;
}

.startbox-wrapper h3 {
    margin-top:1em;
    display:block;
    min-height:96px;
}

.startbox-wrapper a {
    color:var(--link-color);
}

.books-list a {
    display:block;
    text-decoration:none;
}

.books-list a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:1em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img img {
    display:block;
    margin:0 auto;
}

.readmore {
    background: #e4e4e4;
    padding: 7px 0;
    margin-top:0.5em;
    text-align: center;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    color:#222;
}

.startbox-wrapper .readmore svg.fa-icon {
    height:auto;
    width: 1em;
    fill: var(--link-color);
    vertical-align:middle !important;
}
/* zoomboxar */

.boxes.container {
    padding:0;
    margin:1em auto;
}

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    box-shadow: 0 0 0 1em #fff;
    position:relative;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    border: 50px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;
    content: "";
    opacity: 1;
    filter: alpha(opacity=100);
    width: 100px;
    height: 100px;
    transform: scale(7);
    transition: all 0.7s ease-in-out 0s;
    visibility: visible;
    z-index: 1;
            overflow:hidden;

}

.zoombox .boxwrapper:hover:after {
    border-width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    color:white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}
/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li a {
    text-decoration: none;
    display:block;
    padding: 0.5em 1em;
}

.pagination ul li.active a {
    color:white;
}


/* Artiklar
---------------------------------------------------------*/
.blog-post {padding-bottom: 15px; margin-bottom: 15px; border-bottom: 1px solid #d0d0d0;}
.publishdate {color:#909090; font-size:0.95em; display:block; clear:both;  }

.blogg-sidebar > div {padding-bottom: 20px; }
.blogg-sidebar h3 {margin:0 0 5px; padding:0;}
.blogg-sidebar ul {list-style-type:none; margin:0; padding:0;}
.blogg-sidebar ul li {margin:0; padding:0;}
.blogg-tags ul li {display:inline-block; }

.pdf-files {
    margin-bottom:1em;
}
    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}

.search form {
    border:#eee solid 1px;
}

.search button {
    text-align:right;
}
.search button svg.fa-icon {
    fill: #EE592E;
}

.search button, .search input {
    background:transparent !important;
    border:none !important;
    padding:0;
    margin:0.5em;
}

.search button[type=submit]:hover {
    border:none;
    background:transparent;
}

/* Books
---------------------------------------------------------*/

.book-teaser > .inner-content {
    padding-top:0;
}

.bok-moreinfo, .others-books {
    padding:0 2em;
}

.facts {
    padding:1em ;
    margin:1.5em 0;       
}

.facts ul {
    padding:0;
    margin:0;
    list-style-type:none;
}

.facts li {
    padding:0.5em 0;
}

.facts li:last-child {
    margin-bottom:1em;
}

.others-books {
    margin-top:1.5em;
}

.others-books div:first-child {
    margin-left:0;
}


/* news
---------------------------------------------------------*/

.news-item {
    margin-bottom:1em;
}

.date-inner {
    color:#fff;
    padding:0.5em;
}

.news-heading h3 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-bottom:0; 
    margin-left:0.5em;
}

.date .day, .date .month {
    font-size:1rem;
}

.news-item .teaser p {
    margin-top:0.4em;
}

.news .date {
    font-style:italic;
    display:block;
    margin-bottom:0.5em;
}

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

.art-img {
    margin-right:0;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/


footer h4 {
    color:var(--f-color);
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit], .button, button {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
    color:white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover, button:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}



/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
    footer .container {
        text-align:center;
    }
}

.sound-item {
    padding:1em 0;
}

.sound-item:first-child {
    padding-top:0;
}
